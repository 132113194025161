<script>
import { Swiper, SwiperSlide } from 'swiper/vue'
import { Navigation, Pagination } from 'swiper/modules'

// Import Swiper styles
import 'swiper/css'

export default {
  name: 'GeneralSwiper',
  components: {
    Swiper,
    SwiperSlide,
  },
  props: {
    devise: {
      type: Object,
      default: () => {
        return {}
      },
    },
    slidesPerView: {
      type: Number,
      default: 1,
    },
    looping: {
      type: Boolean,
      default: true,
    },
    pagination: {
      type: Boolean,
      default: true,
    },
    paginationPosition: {
      type: String,
      default: 'justify-center',
    },
    arrowColor: {
      type: String,
      default: 'white',
    },
    arrowBackgroudColor: {
      type: String,
      default: 'transparent',
    },
    arrowBorder: {
      type: String,
      default: 'rounded-full',
    },
    arrowSize: {
      type: String,
      default: 'h-16 w-16',
    },
    arrowPosition: {
      type: String,
      default: 'justify-between',
    },
    slideAnimate: {
      type: Boolean,
      default: false,
    },
    slideClass: {
      type: String,
      default: '',
    },
    slideStyle: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      swiper: null,
      activeIndex: 1,
      modules: [Pagination, Navigation],
      slideRefs: [],
    }
  },
  computed: {
    slides() {
      const slides = []

      if (this.$slots?.default) {
      // Loop through all the elemtns of the default slot
        this.$slots.default().forEach((vnode) => {
        // Remove the empty nodes. There may be more to do here but this removes any nodes from Vue
        // that are not components, elements, etc
          if (typeof vnode.type !== 'symbol') {
            slides.push(vnode)
          }
          else {
          // The node may be a v-for loop. If so, we need to loop through the children and add them
          // For some reason it comes through an an object that looks like an array. Vue.js has brains
          // we don't have :)
            if (typeof vnode.children === 'object') {
              Object.keys(vnode.children).forEach((key) => {
                const child = vnode.children[key]
                if (typeof child.type !== 'symbol')
                  slides.push(child)
              })
            }
          }
        })
      }
      return slides
    },
    animateSlideNext() {
      if (this.slideAnimate) {
        return '50px !important'
      }
      return '0'
    },
    numberOfSlides() {
      return this.slides.length
    },
  },
  methods: {
    setSwiperRef(swiper) {
      this.swiper = swiper
    },
    next() {
      this.swiper.slideNext()
    },
    prev() {
      this.swiper.slidePrev()
    },
    goToSlide(index) {
      this.swiper.slideTo(index)
    },
    onActiveIndexChange(swiper) {
      if (swiper) {
        const index = swiper.activeIndex % this.numberOfSlides
        if (index)
          this.activeIndex = index
        else
          this.activeIndex = this.numberOfSlides
      }
    },
  },
}
</script>

<template>
  <div class="relative w-full" :class="{ 'swiper-no-swiping': slides.length < 2 }">
    <ClientOnly>
      <Swiper
        class="w-full"
        :slides-per-view="slidesPerView"
        :loop="looping"
        :pagination="{
          clickable: true,
        }"
        :centered-slides="false"
        :navigation="true"
        :modules="modules"
        @swiper="setSwiperRef"
        @active-index-change="onActiveIndexChange"
      >
        <SwiperSlide v-for="(slide, key) in slides" :key="key" :class="slideClass" :style="slideStyle">
          <component :is="slide" :class="[]" />
        </SwiperSlide>
      </Swiper>
    </ClientOnly>

    <div
      v-if="numberOfSlides > 1"
      class="absolute z-10 inset-0 pointer-events-none flex items-center p-4"
      :class="arrowPosition"
    >
      <button class="pointer-events-auto" :class="`${arrowBackgroudColor} ${arrowSize} ${arrowBorder}`" aria-label="Left Navigation" @click="prev">
        <Icon :style="{ color: arrowColor }" :class="`${arrowSize}`" name="heroicons:chevron-left-20-solid" />
      </button>
      <button class="pointer-events-auto" :class="`${arrowBackgroudColor} ${arrowSize} ${arrowBorder}`" aria-label="Right Navigation" @click="next">
        <Icon :style="{ color: arrowColor }" :class="`${arrowSize}`" name="heroicons:chevron-right-20-solid" />
      </button>
    </div>

    <div
      v-if="numberOfSlides > 1 && pagination"
      class="absolute z-10 inset-0 pointer-events-none flex items-end pb-8"
      :class="paginationPosition"
    >
      <div class="flex space-x-2">
        <div
          v-for="n in numberOfSlides"
          :key="n"
          class="w-2 h-2 rounded-full"
          :class="{
            'opacity-50': n !== activeIndex && (activeIndex !== 0 || n !== 1),
          }"
          :style="{
            backgroundColor: arrowColor,
          }"
        />
      </div>
    </div>
  </div>
</template>

<style scoped>
.swiper-slide {
  width: auto;
  height: auto;
}
</style>

<style>
.swiper-wrapper {
  @apply items-stretch;
}
.swiper-slide-next {
  position: relative;
  top: v-bind(animateSlideNext) !important;
  transition: top 1s ease !important;
}
.swiper-slide {
  position: relative;
  top: 0;
  transition: top 1s ease !important;
}
</style>
