<script setup lang="ts">
import { onMounted } from 'vue'

defineLayout({
  label: 'Seadust Layout',
})

const links: Array<object> = [
  { rel: 'icon', href: '/imgs/playa/favicons/icon-192.png', type: 'image/png' },
  { rel: 'icon', href: '/imgs/playa/favicons/icon.svg', type: 'image/svg+xml' },
  { rel: 'apple-touch-icon', href: '/imgs/playa/favicons/apple-touch-icon.png', type: 'image/png' },
  { rel: 'manifest', href: '/imgs/playa/favicons/manifest.json', type: 'application/json' },
]

const isVoixTemplatizer = inject<boolean>('isVoixTemplatizer', false)
const { getCommonLayoutData, playaLayoutMounted, setupHeader } = usePlayaLayout()

if (!isVoixTemplatizer)
  setupHeader('a27adfd1-a837-4704-97c4-5b910df8afc6', links)

const {
  resorts,
  currentResort,
  footerCompanyMenu,
  footerResourcesMenu,
  footerTermsMenu,
} = await getCommonLayoutData(isVoixTemplatizer, ['brands'])

onMounted(() => {
  if (!isVoixTemplatizer)
    return
  playaLayoutMounted(currentResort)
})

provide('accentColor', { value: '#cbb387' })
</script>

<template>
  <div>
    <div
      class="seadust-theme"
    >
      <div id="app">
        <div id="portal-destination" transition="fade-transition" />

        <SeadustNavbar
          v-if="!isVoixTemplatizer"
          :current-resort="currentResort?.data"
          :resorts="resorts?.data"
        />

        <slot />

        <SeadustFooter
          v-if="!isVoixTemplatizer"
          :current-resort="currentResort?.data"
          :footer-company-menu="footerCompanyMenu?.data"
          :footer-resources-menu="footerResourcesMenu?.data"
          :footer-terms-menu="footerTermsMenu?.data"
        />
      </div>
    </div>
  </div>
</template>

<style lang="scss">
  @import '~/assets/sass/seadust.scss';
</style>
