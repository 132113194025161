export default {
  "agent-cash-plus": () => import("/Users/cdm/code/turbo/playaresorts/apps/voix-app/layouts/AgentCashPlus.vue"),
  blocks: () => import("/Users/cdm/code/turbo/playaresorts/apps/voix-app/layouts/Blocks.vue"),
  bonita: () => import("/Users/cdm/code/turbo/playaresorts/apps/voix-app/layouts/Bonita.vue"),
  hilton: () => import("/Users/cdm/code/turbo/playaresorts/apps/voix-app/layouts/Hilton.vue"),
  "hilton-yucatan": () => import("/Users/cdm/code/turbo/playaresorts/apps/voix-app/layouts/HiltonYucatan.vue"),
  "hyatt-zilara": () => import("/Users/cdm/code/turbo/playaresorts/apps/voix-app/layouts/HyattZilara.vue"),
  "hyatt-ziva": () => import("/Users/cdm/code/turbo/playaresorts/apps/voix-app/layouts/HyattZiva.vue"),
  "infinity-luxury": () => import("/Users/cdm/code/turbo/playaresorts/apps/voix-app/layouts/InfinityLuxury.vue"),
  jewel: () => import("/Users/cdm/code/turbo/playaresorts/apps/voix-app/layouts/Jewel.vue"),
  "jewel-grande": () => import("/Users/cdm/code/turbo/playaresorts/apps/voix-app/layouts/JewelGrande.vue"),
  kimpton: () => import("/Users/cdm/code/turbo/playaresorts/apps/voix-app/layouts/Kimpton.vue"),
  "no-chrome": () => import("/Users/cdm/code/turbo/playaresorts/apps/voix-app/layouts/NoChrome.vue"),
  "playa-cares": () => import("/Users/cdm/code/turbo/playaresorts/apps/voix-app/layouts/PlayaCares.vue"),
  "playa-collection": () => import("/Users/cdm/code/turbo/playaresorts/apps/voix-app/layouts/PlayaCollection.vue"),
  playaresorts: () => import("/Users/cdm/code/turbo/playaresorts/apps/voix-app/layouts/Playaresorts.vue"),
  sanctuary: () => import("/Users/cdm/code/turbo/playaresorts/apps/voix-app/layouts/Sanctuary.vue"),
  sanctuary2024: () => import("/Users/cdm/code/turbo/playaresorts/apps/voix-app/layouts/Sanctuary2024.vue"),
  sandpiper: () => import("/Users/cdm/code/turbo/playaresorts/apps/voix-app/layouts/Sandpiper.vue"),
  seadust: () => import("/Users/cdm/code/turbo/playaresorts/apps/voix-app/layouts/Seadust.vue"),
  wyndham: () => import("/Users/cdm/code/turbo/playaresorts/apps/voix-app/layouts/Wyndham.vue"),
  "wyndham-cancun": () => import("/Users/cdm/code/turbo/playaresorts/apps/voix-app/layouts/WyndhamCancun.vue"),
  "wyndham-playa": () => import("/Users/cdm/code/turbo/playaresorts/apps/voix-app/layouts/WyndhamPlaya.vue")
}