<script setup lang="ts">
import { usePageStore } from '@voix/store/pageStore'

// Config
const props = defineProps<{
  currentResort: {
    type: object
  }
  footerCompanyMenu: {
    type: object
    required: true
  }
  footerResourcesMenu: {
    type: object
    required: true
  }
  footerTermsMenu: {
    type: object
    required: false
  }
  bgType: {
    type: string
    default: 'dark'
  }
  config: {
    type: object
    require: false
    default: () => {
      crossPromotion: true
      signUpForm: false
      termsMenuPosition: 'left'
    }
  }
}>()

// Composables
const pageStore = usePageStore()

// State
const isVisible = ref(false)

// Computed
const currentPage = pageStore.currentPage
const languageCode = computed(() => {
  return currentPage?.language_code === 'es' ? 'es/' : ''
})
const enLink = computed(() => {
  if (currentPage?.language_code === 'en')
    return { path: currentPage.path, language_code: currentPage.language_code }

  return currentPage?.localizations.find(
    (localization: any) => localization.language_code === 'en',
  )
})
const esLink = computed(() => {
  if (currentPage?.language_code === 'es')
    return { path: currentPage.path, language_code: currentPage.language_code }

  return currentPage?.localizations.find(
    (localization: any) => localization.language_code === 'es',
  )
})
const footerSettings = computed(() => {
  return props.currentResort.footer_settings
})
const backgroundHeart = computed(() => {
  if (props.bgType === 'white') {
    return {
      backgroundImage:
        'url(https://playa-media.s3.us-east-2.amazonaws.com/uploads/playa_heart_white.png)',
    }
  }
  else {
    return {
      backgroundImage:
        'url(https://playa-media.s3.us-east-2.amazonaws.com/uploads/playa_heart.png)',
    }
  }
})

// Methods
function visibilityChanged(entry: any) {
  if (entry.intersectionRatio * 100 > 0)
    isVisible.value = true
}
function openLink(link: string) {
  const url = link
  if (url)
    window.open(url, '_blank')
}
</script>

<template>
  <div class="flex justify-center min-h-[285px] bg-white">
    <!-- Footer -->
    <div
      class="flex flex-col w-full lg:w-[1160px] mx-0 lg:mx-8 border-t-2 border-color-primary"
    >
      <!-- Top -->
      <div
        class="flex flex-col-reverse lg:flex-row justify-between items-center h-full lg:h-32 py-6"
      >
        <!-- Address -->
        <div class="flex flex-col text-center lg:text-left mt-8 lg:mt-0">
          <p>Blvd. Kukulcán Km.17, Lote 52-04. Zona Hotelera</p>
          <p>Reservations US & CA: 1 (844) 781-1158</p>
          <p>Resort Phone (TEL): +52 (998) 881-6713</p>
        </div>

        <!-- Logo -->
        <a href="/en" class="relative z-10">
          <LogosSeadust id="logo" class="w-[174px]" :fill="false" />
        </a>
      </div>

      <!-- Middle -->
      <div
        class="flex flex-col lg:flex-row justify-between items-center border-y-[1.5px] border-grey-200 h-full lg:h-20 py-6"
      >
        <!-- Links -->
        <div
          class="flex flex-col lg:flex-row justify-center lg:justify-start items-center lg:divide-x divide-[#0070b4] w-full"
        >
          <NuxtLink
            :to="`/${languageCode}contact`"
            class="text-color-primary px-2 hover:underline pr-4"
          >
            {{ $t("contact") }}
          </NuxtLink>

          <NuxtLink
            :to="`https://www.playaresorts.com/${languageCode}privacy-policy`"
            class="text-color-primary px-2 hover:underline px-4"
          >
            {{ $t("privacy-policy") }}
          </NuxtLink>

          <NuxtLink
            :to="`https://www.playaresorts.com/${languageCode}terms-conditions/?_gl=1*8i85ql*_gcl_au*NjM4MTk3MDM4LjE3MzYzNjU2MjY.*_ga*NDA3MDM4Mzk3LjE3MzYzNjU2MjY.*_ga_C4Q9DHK3FJ*MTczNjUyNzE0Ni4xMS4wLjE3MzY1MjcyNDguNjAuMC4xNzk5MTAxMzQy`"
            class="text-color-primary px-2 hover:underline px-4"
            target="_blank"
          >
            {{ $t("terms-and-conditions") }}
          </NuxtLink>

          <NuxtLink
            :to="`https://infinityluxurytravelclub.com/${languageCode}?_gl=1*1edbp2p*_gcl_au*NjM4MTk3MDM4LjE3MzYzNjU2MjY.*_ga*NDA3MDM4Mzk3LjE3MzYzNjU2MjY.*_ga_C4Q9DHK3FJ*MTczNjUxOTUxNi4xMC4xLjE3MzY1MjE5MzQuMzEuMC4xMzUzODE1Nzcx`"
            class="text-color-primary px-2 hover:underline px-4"
            target="_blank"
          >
            {{ $t("luxury-club") }}
          </NuxtLink>

          <NuxtLink
            to="https://agentcashplus.com/"
            class="text-color-primary px-2 hover:underline px-4"
            target="_blank"
          >
            {{ $t("travel-professionals") }}
          </NuxtLink>
        </div>

        <!-- Social -->
        <div
          class="flex flex-row justify-center lg:justify-end items-center space-x-4 mt-8 lg:mt-0"
        >
          <Icon
            class="text-color-primary"
            name="simple-icons:facebook"
            size="24"
            @click="openLink('https://www.facebook.com/SeadustCancun')"
          />
          <Icon
            class="text-color-primary"
            name="simple-icons:twitter"
            size="24"
            @click="openLink('https://twitter.com/SeadustCancun')"
          />
          <Icon
            class="text-color-primary"
            name="simple-icons:instagram"
            size="24"
            @click="
              openLink(
                'https://www.tripadvisor.com/Hotel_Review-g150807-d634235-Reviews-Seadust_Cancun_Family_Resort-Cancun_Yucatan_Peninsula.html',
              )
            "
          />
          <Icon
            class="text-color-primary"
            name="simple-icons:tripadvisor"
            size="24"
            @click="openLink('https://www.instagram.com/seadustcancun')"
          />
          <Icon
            class="text-color-primary"
            name="simple-icons:pinterest"
            size="24"
            @click="openLink('https://www.youtube.com/channel/UC8uT4dgDa9c_DGZvR7FHdow')"
          />
          <Icon
            class="text-color-primary"
            name="simple-icons:youtube"
            size="24"
            @click="openLink('https://www.pinterest.com.mx/seadustcancunfamilyresort/')"
          />
          <Icon
            class="text-color-primary"
            name="simple-icons:tiktok"
            size="24"
            @click="openLink('https://www.tiktok.com/@seadustcancun')"
          />
        </div>
      </div>

      <!-- Bottom -->
      <div class="flex flex-row justify-between items-center py-2">
        <!-- Language -->
        <div class="flex items-center divide-x divide-black">
          <NuxtLink
            v-if="enLink"
            :key="enLink?.path"
            :to="enLink?.path"
            class="uppercase px-2 text-color-primary hover:underline"
          >
            EN
          </NuxtLink>
          <NuxtLink
            v-if="esLink"
            :key="esLink?.path"
            :to="esLink?.path"
            class="uppercase px-2 text-color-primary hover:underline"
          >
            ES
          </NuxtLink>
        </div>

        <!-- Awards -->
        <div class="flex flex-row justify-between space-x-2 items-center">
          <img src="/imgs/seadust/awards/TA.png">
          <img src="/imgs/seadust/awards/TC.jpg">
          <img src="/imgs/seadust/awards/SS.png">
          <img src="/imgs/seadust/awards/CRT.jpg">
        </div>
      </div>
    </div>
  </div>
</template>
