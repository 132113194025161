<script setup lang="ts">
import type {
  SliceInterface,
  VoixLinkFieldInterface,
  VoixMediaFieldInterface,
  VoixTextFieldInterface,
  VoixWysiwygFieldInterface,
} from '@voix/types'
import ContentHeading from '@/components/content/Heading.vue'

defineProps<{
  fields: {
    mainTitle: VoixTextFieldInterface
    mainContent: VoixWysiwygFieldInterface
    imageOne: VoixMediaFieldInterface
    titleOne: VoixTextFieldInterface
    contentOne: VoixWysiwygFieldInterface
    linkOne: VoixLinkFieldInterface
    imageTwo: VoixMediaFieldInterface
    titleTwo: VoixTextFieldInterface
    contentTwo: VoixWysiwygFieldInterface
    linkTwo: VoixLinkFieldInterface
    imageThree: VoixMediaFieldInterface
    titleThree: VoixTextFieldInterface
    contentThree: VoixWysiwygFieldInterface
    linkThree: VoixLinkFieldInterface
    imageFour: VoixMediaFieldInterface
    titleFour: VoixTextFieldInterface
    contentFour: VoixWysiwygFieldInterface
    linkFour: VoixLinkFieldInterface
    imageFive: VoixMediaFieldInterface
    titleFive: VoixTextFieldInterface
    contentFive: VoixWysiwygFieldInterface
    linkFive: VoixLinkFieldInterface
  }
  slice: SliceInterface
}>()

defineSlice({
  fields: {
    mainTitle: { type: 'text', label: 'Main Title' },
    mainContent: { type: 'wysiwyg', label: 'Main Content' },
    imageOne: {
      type: 'media',
      label: 'Image One',
      breakpoints: { lg: { width: 400, height: 530 }, default: { width: 370, height: 250 } },
    },
    titleOne: { type: 'text', label: 'Title One' },
    contentOne: { type: 'wysiwyg', label: 'Content One' },
    linkOne: { type: 'link', label: 'Link One' },
    imageTwo: {
      type: 'media',
      label: 'Image Two',
      breakpoints: { lg: { width: 366, height: 253 }, default: { width: 370, height: 250 } },
    },
    titleTwo: { type: 'text', label: 'Title Two' },
    contentTwo: { type: 'wysiwyg', label: 'Content Two' },
    linkTwo: { type: 'link', label: 'Link Two' },
    imageThree: {
      type: 'media',
      label: 'Image Three',
      breakpoints: { lg: { width: 366, height: 253 }, default: { width: 370, height: 250 } },
    },
    titleThree: { type: 'text', label: 'Title Three' },
    contentThree: { type: 'wysiwyg', label: 'Content Three' },
    linkThree: { type: 'link', label: 'Link Three' },
    imageFour: {
      type: 'media',
      label: 'Image Four',
      breakpoints: { lg: { width: 366, height: 253 }, default: { width: 370, height: 250 } },
    },
    titleFour: { type: 'text', label: 'Title Four' },
    contentFour: { type: 'wysiwyg', label: 'Content Four' },
    linkFour: { type: 'link', label: 'Link Four' },
    imageFive: {
      type: 'media',
      label: 'Image Five',
      breakpoints: { lg: { width: 366, height: 253 }, default: { width: 370, height: 250 } },
    },
    titleFive: { type: 'text', label: 'Title Five' },
    contentFive: { type: 'wysiwyg', label: 'Content Five' },
    linkFive: { type: 'link', label: 'Link Five' },
  },
  name: {
    label: 'Seadust Five Image Grid Links',
    group: 'Seadust',
    layouts: ['Seadust'],
  },
  templates: [
    {
      label: 'Seadust Five Image Grid Links',
      fields: {
        contentFive: {
          value:
            '<p>Let yourself be amazed\n                        by the complete <strong>all-inclusive\n                            service at Seadust Cancun Family Resort<\/strong>: a gastronomic experience with 10\n                        restaurants and 10 bars\n                        which are ideal for every moment of the day.<\/p>',
        },
        contentFour: {
          value:
            '<p>Starting a new life\n                        together deserves a spectacular\n                        setting that\n                        sets the course for the years to come. Enjoy your wedding together with your family and friends\n                        in a\n                        wonderful place.<\/p>',
        },
        contentOne: {
          value:
            '<p><strong>The Spa at\n                        Seadust<\/strong> is the gateway to a\n                    journey\n                    towards your personal well-being. You can find a physical and emotional balance during your time\n                    with us\n                    through exclusive treatments performed by our professional relaxation experts.<\/p>',
        },
        contentThree: {
          value:
            '<p>Seadust Cancun Family\n                        Resort is the perfect vacation\n                        destination\n                        for everyone. Explore all the activities that we have to offer so that you can enjoy a trip with\n                        the best\n                        facilities and services.<\/p>',
        },
        contentTwo: {
          value:
            '<p>Our facilities for\n                        conventions, congresses, business\n                        meetings,\n                        among others, will make your event a unique experience that provides full satisfaction to\n                        participants and\n                        organizers.<\/p>',
        },
        imageFive: {
          value:
            'https:\/\/playa-cms-assets.s3.amazonaws.com\/media\/seadust\/home\/gastronamy-sm.jpeg',
        },
        imageFour: {
          value:
            'https:\/\/playa-cms-assets.s3.amazonaws.com\/media\/seadust\/2023\/seadust-cancun-oceanview-wedding-couple-6.jpg',
        },
        imageOne: {
          value:
            'https:\/\/playa-cms-assets.s3.amazonaws.com\/media\/seadust\/2023\/seadust-cancun-spa-couple-2.jpg',
        },
        imageThree: {
          value:
            'https:\/\/playa-cms-assets.s3.amazonaws.com\/media\/seadust\/2023\/seadust-cancun-water-park-family-2.jpg',
        },
        imageTwo: {
          value:
            'https:\/\/playa-cms-assets.s3.amazonaws.com\/media\/seadust\/conventions\/bay.jpeg',
        },
        linkFive: {
          value: {
            text: 'See More',
            target: '_self',
            relationship: null,
            href: '\/new-page',
          },
        },
        linkFour: {
          value: {
            text: 'See More',
            target: '_self',
            relationship: null,
            href: '\/new-page',
          },
        },
        linkOne: {
          value: {
            text: 'See More',
            target: '_blank',
            relationship: null,
            href: '\/new-page',
          },
        },
        linkThree: {
          value: {
            text: 'See More',
            target: '_self',
            relationship: null,
            href: '\/new-page',
          },
        },
        linkTwo: {
          value: {
            text: 'See More',
            target: '_self',
            relationship: null,
            href: '\/new-page',
          },
        },
        mainContent: {
          value:
            'The true meaning of being together can be fully enjoyed at our\n                all-inclusive resort. Enjoy an unforgettable vacation by exploring the turquoise waters along our\n                beaches, take\n                a dip in one of our pools with views of the ocean, and let your children have fun on the slides of our\n                exciting\n                water park <strong>Treasure Island<\/strong>. Allow our expert staff at <strong>The Spa at\n                    Seadust<\/strong> to\n                pamper you with a massage next to the sea, in our private cabins, or with a couple\u2019s treatment. And of\n                course,\n                choose whatever else you would like to do among all the activities and facilities that we offer for you\n                and your\n                family.',
        },
        mainTitle: { value: 'Discover the paradise' },
        titleFive: { value: 'Dining & Drinks' },
        titleFour: { value: 'Weddings' },
        titleOne: { value: 'Spa' },
        titleThree: { value: 'experiences' },
        titleTwo: { value: 'conventions' },
      },
    },
  ],
})

const mouseOver = ref('')
</script>

<template>
  <div
    class="flex flex-col justify-start items-center relative px-0 pt-12 xl:pt-24 pb-24"
  >
    <!-- Heading & Copy -->
    <div class="w-full xl:w-[1174px] h-fit flex flex-row justify-center xl:justify-start">
      <div class="mb-16 px-8 xl:px-0">
        <ContentHeading :title="fields.mainTitle.value" class="mb-5" />
        <VoixWysiwyg class="copy !leading-snug" :field="fields.mainContent" />
      </div>
    </div>

    <!-- Gallery -->
    <div class="grid grid-cols-1 lg:grid-cols-3 gap-2 lg:gap-8">
      <!-- Left Column -->
      <figure
        class="col-span-1 relative bg-[#000] h-[300px] lg:h-full w-screen lg:max-w-[284px] xl:max-w-[370px]"
      >
        <VoixMedia
          :field="fields.imageOne"
          class="h-full w-full object-cover opacity-[0.70]"
        />

        <p class="font-subtitle text-2xl uppercase text-white absolute top-6 left-6 z-10">
          {{ fields.titleOne.value }}
        </p>

        <figcaption
          class="flex flex-col justify-center items-start space-y-8 absolute top-0 left-0 w-full h-full bg-color-accent opacity-0 hover:opacity-100 duration-500 p-8 z-20"
          @mouseover="mouseOver = 'imageOne'"
          @mouseleave="mouseOver = ''"
        >
          <VoixWysiwyg :field="fields.contentOne" class="relative font-copy text-white duration-500" :class="{ 'top-0': mouseOver === 'imageOne', 'top-8': mouseOver !== 'imageOne' }" />
          <VoixLink
            :field="fields.linkOne"
            class="link link-white animated-underline animated-underline-white relative duration-500"
            :class="{ 'top-4': mouseOver === 'imageOne', '-top-4': mouseOver !== 'imageOne' }"
          />
        </figcaption>
      </figure>

      <!-- Right Column -->
      <div
        class="col-span-1 lg:col-span-2 grid grid-cols-1 lg:grid-cols-2 gap-2 lg:gap-y-8 lg:gap-x-4"
      >
        <figure
          class="relative bg-abs-black h-[300px] lg:h-full w-screen lg:max-w-[280px] xl:max-w-[366px]"
        >
          <VoixMedia
            :field="fields.imageTwo"
            class="h-full w-full object-cover opacity-[0.70]"
          />

          <p class="font-subtitle text-2xl uppercase text-white absolute top-6 left-6">
            {{ fields.titleTwo.value }}
          </p>

          <figcaption
            class="flex flex-col justify-center items-start space-y-4 absolute top-0 left-0 w-full h-full bg-color-accent opacity-0 hover:opacity-100 duration-500 p-8 z-20"
            @mouseover="mouseOver = 'imageTwo'"
            @mouseleave="mouseOver = ''"
          >
            <VoixWysiwyg :field="fields.contentTwo" class="relative font-copy lg:text-[12.5px] xl:text-base text-white duration-500" :class="{ 'top-0': mouseOver === 'imageTwo', 'top-8': mouseOver !== 'imageTwo' }" />
            <VoixLink
              :field="fields.linkTwo"
              class="link link-white animated-underline animated-underline-white relative duration-500"
              :class="{ 'top-2': mouseOver === 'imageTwo', '-top-2': mouseOver !== 'imageTwo' }"
            />
          </figcaption>
        </figure>

        <figure
          class="relative bg-abs-black h-[300px] lg:h-full w-screen lg:max-w-[280px] xl:max-w-[366px]"
        >
          <VoixMedia
            :field="fields.imageThree"
            class="h-full w-full object-cover opacity-[0.70]"
          />

          <p class="font-subtitle text-2xl uppercase text-white absolute top-6 left-6">
            {{ fields.titleThree.value }}
          </p>

          <figcaption
            class="flex flex-col justify-center items-start space-y-4 absolute top-0 left-0 w-full h-full bg-color-accent opacity-0 hover:opacity-100 duration-500 p-8 z-20"
            @mouseover="mouseOver = 'imageThree'"
            @mouseleave="mouseOver = ''"
          >
            <VoixWysiwyg :field="fields.contentThree" class="relative font-copy lg:text-[12.5px] xl:text-base text-white duration-500" :class="{ 'top-0': mouseOver === 'imageThree', 'top-8': mouseOver !== 'imageThree' }" />
            <VoixLink
              :field="fields.linkThree"
              class="link link-white animated-underline animated-underline-white relative duration-500"
              :class="{ 'top-2': mouseOver === 'imageThree', '-top-2': mouseOver !== 'imageThree' }"
            />
          </figcaption>
        </figure>

        <figure
          class="relative bg-abs-black h-[300px] lg:h-full w-screen lg:max-w-[280px] xl:max-w-[366px]"
        >
          <VoixMedia
            :field="fields.imageFour"
            class="h-full w-full object-cover opacity-[0.70]"
          />

          <p class="font-subtitle text-2xl uppercase text-white absolute top-6 left-6">
            {{ fields.titleFour.value }}
          </p>

          <figcaption
            class="flex flex-col justify-center items-start space-y-4 absolute top-0 left-0 w-full h-full bg-color-accent opacity-0 hover:opacity-100 duration-500 p-8 z-20"
            @mouseover="mouseOver = 'imageFour'"
            @mouseleave="mouseOver = ''"
          >
            <VoixWysiwyg :field="fields.contentFour" class="relative font-copy lg:text-[12.5px] xl:text-base text-white duration-500" :class="{ 'top-0': mouseOver === 'imageFour', 'top-8': mouseOver !== 'imageFour' }" />
            <VoixLink
              :field="fields.linkFour"
              class="link link-white animated-underline animated-underline-white relative duration-500"
              :class="{ 'top-2': mouseOver === 'imageFour', '-top-2': mouseOver !== 'imageFour' }"
            />
          </figcaption>
        </figure>

        <figure
          class="relative bg-abs-black h-[300px] lg:h-full w-screen lg:max-w-[280px] xl:max-w-[366px]"
        >
          <VoixMedia
            :field="fields.imageFive"
            class="h-full w-full object-cover opacity-[0.70]"
          />

          <p class="font-subtitle text-2xl uppercase text-white absolute top-6 left-6">
            {{ fields.titleFive.value }}
          </p>

          <figcaption
            class="flex flex-col justify-center items-start space-y-4 absolute top-0 left-0 w-full h-full bg-color-accent opacity-0 hover:opacity-100 duration-500 p-8 z-20"
            @mouseover="mouseOver = 'imageFive'"
            @mouseleave="mouseOver = ''"
          >
            <VoixWysiwyg :field="fields.contentFive" class="relative font-copy lg:text-[12.5px] xl:text-base text-white duration-500" :class="{ 'top-0': mouseOver === 'imageFive', 'top-8': mouseOver !== 'imageFive' }" />
            <VoixLink
              :field="fields.linkFive"
              class="link link-white animated-underline animated-underline-white relative duration-500"
              :class="{ 'top-2': mouseOver === 'imageFive', '-top-2': mouseOver !== 'imageFive' }"
            />
          </figcaption>
        </figure>
      </div>
    </div>

    <!-- Background -->
    <div
      class="after:h-[50%] after:w-full after:bg-color-secondary after:absolute after:bottom-0 after:left-0 after:z-[-1]"
    />
  </div>
</template>
