import revive_payload_client_YTGlmQWWT5 from "/Users/cdm/code/turbo/playaresorts/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_neSs9z3UJp from "/Users/cdm/code/turbo/playaresorts/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_VeD7Ba96AP from "/Users/cdm/code/turbo/playaresorts/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_VTarsGAzgb from "/Users/cdm/code/turbo/playaresorts/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_PKIXU5KmsI from "/Users/cdm/code/turbo/playaresorts/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_LIYcCMJD18 from "/Users/cdm/code/turbo/playaresorts/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_SeG0EjL5Ec from "/Users/cdm/code/turbo/playaresorts/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_Ib6KnAhqrq from "/Users/cdm/code/turbo/playaresorts/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/Users/cdm/code/turbo/playaresorts/apps/voix-app/.nuxt/components.plugin.mjs";
import prefetch_client_8FoqvPFTot from "/Users/cdm/code/turbo/playaresorts/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import voix_plugin_LLgXl3v5eW from "/Users/cdm/code/turbo/playaresorts/node_modules/@logicbomb-media/voix/dist/runtime/plugins/voix.plugin.js";
import nuxt_plugin_PygVnJiOSv from "/Users/cdm/code/turbo/playaresorts/node_modules/nuxt-delay-hydration/dist/runtime/nuxt-plugin.js";
import switch_locale_path_ssr_lCxu0ZmxIx from "/Users/cdm/code/turbo/playaresorts/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_5C7QFp7tsq from "/Users/cdm/code/turbo/playaresorts/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import plugin_SUHNTPgv3p from "/Users/cdm/code/turbo/playaresorts/node_modules/nuxt-icon/dist/runtime/plugin.js";
import mothership_TGDAo1rhTZ from "/Users/cdm/code/turbo/playaresorts/apps/voix-app/plugins/mothership.ts";
import playa_plugin_0rhIkmsieM from "/Users/cdm/code/turbo/playaresorts/apps/voix-app/plugins/playa.plugin.ts";
export default [
  revive_payload_client_YTGlmQWWT5,
  unhead_neSs9z3UJp,
  router_VeD7Ba96AP,
  payload_client_VTarsGAzgb,
  navigation_repaint_client_PKIXU5KmsI,
  check_outdated_build_client_LIYcCMJD18,
  chunk_reload_client_SeG0EjL5Ec,
  plugin_vue3_Ib6KnAhqrq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_8FoqvPFTot,
  voix_plugin_LLgXl3v5eW,
  nuxt_plugin_PygVnJiOSv,
  switch_locale_path_ssr_lCxu0ZmxIx,
  i18n_5C7QFp7tsq,
  plugin_SUHNTPgv3p,
  mothership_TGDAo1rhTZ,
  playa_plugin_0rhIkmsieM
]